<!--
 * @Author: lzh
 * @Date: 2022-07-05 10:15:39
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:42:14
 * @Description: file content
-->
<template>
  <div class="edit-our-clients">
    <div class="cell-b">
      <h3>輪播-配置</h3>
      <selfCell title="開啟輪播">
        <el-radio
          v-model="configs.autoplay"
          @change="setValue('autoplay')"
          :label="false"
        >否</el-radio>
        <el-radio
          v-model="configs.autoplay"
          @change="setValue('autoplay')"
          :label="true"
        >是</el-radio>
      </selfCell>
      <selfCell
        title="輪播間隔時長"
        v-if="configs.autoplay"
      >
        <el-radio
          v-model="configs.interval"
          :label="1"
          @change="setValue('interval')"
        >1S</el-radio>
        <el-radio
          v-model="configs.interval"
          @change="setValue('interval')"
          :label="2"
        >2S</el-radio>
        <el-radio
          v-model="configs.interval"
          @change="setValue('interval')"
          :label="3"
        >3S</el-radio>
      </selfCell>
      <selfCell title="開啟循環">
        <el-radio
          v-model="configs.loop"
          @change="setValue('loop')"
          :label="false"
        >否</el-radio>
        <el-radio
          v-model="configs.loop"
          @change="setValue('loop')"
          :label="true"
        >是</el-radio>
      </selfCell>
      <h3>項-標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
      <h3>項-描述配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

    </div>
    <div class="cell-b">
      <h3>選擇圖片</h3>
      <div class="item-box">
        <div
          class="item cell-b"
          v-for="(item, index) in configs.items"
          :key="index"
        >
          <i
            class="remove el-icon-delete"
            @click.stop="removeItem(index)"
          />
          <selfUpload
            v-model="item.img"
            type="default"
            @change="setValue('items')"
          />
          <el-input
            v-model="item.alt"
            type="textarea"
            @change="setValue('items')"
            placeholder="請輸入圖片描述"
          />
        </div>
        <div
          @click="addItem"
          class="add"
        >+</div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import { getByName } from '@/js/utils/default_config';
export default {
  name: 'edit-our-clients',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          autoplay: true, // 是否自動輪播
          interval: '3', // 時間
          items: [],
        };
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    addItem () {
      let mod = getByName('ourClients');
      let item = this.$util.object.clone(mod.configs.items[0]);
      let _ = this.configs.items;
      _.push(item);
      this.$forceUpdate();
    },
    removeItem (index) {
      let _ = this.configs.items;
      _.splice(index, 1);
      this.$forceUpdate();
      this.setValue('items');
    },
  },
};
</script>

<style lang="less" scoped>
.edit-our-clients {
  .item-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .item {
      width: 100%;
      margin-top: 20px;
      position: relative;
      /deep/.com__upload-box {
        // height: 100%;
      }
      /deep/.avatar-uploader {
        height: 100%;
      }
      /deep/.el-upload {
        height: 100%;
      }
      /deep/.el-upload img {
        height: 100%;
      }
      .remove {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 32px;
        z-index: 10;
        &:hover {
          color: var(--themeColor);
        }
      }
      &:hover {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.3),
          -2px -2px 8px 1px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
      }
    }
    .add {
      border: 1px solid #ccc;
      width: 100%;
      padding: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-top: 20px;
      &:hover {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25),
          -2px -2px 8px 1px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
      }
    }
  }
}
</style>
